<template>
    <div class="yt-container" v-html="block.embed.html"></div>
</template>

<script>
export default {
    name: 'YouTubeBlock',
    props: {
        block: Object
    }
}
</script>

<style scoped>
.yt-container {
    width: 100%;
}

@media screen and (min-width: 900px) {
}
</style>