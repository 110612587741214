<template>
    <div class="link-container">
        <a :href="block.source.url" target="_blank">
            <img :class="{instagram: block.source.provider.name === 'Instagram'}" :src="block.image.display.url" />
        </a>
        <div class="caption">
            <a :href="block.source.url" target="_blank">{{block.title}}</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LinkBlock',
    props: {
        block: Object
    }
}
</script>

<style scoped>
.link-container {
    max-width: 600px;
    font-family: 'Helvetica';
}
img {
    width: 100%;
    border-radius: 8px;
    border: 4px solid #ffffff;
    box-sizing: border-box;
}
img.instagram {
    border-radius: 0;
    border: none;
}
</style>