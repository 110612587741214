<template>
    <div class="text-container">
        <p>{{block.content}}</p>
        <a :href="block.source.url" target="_blank">{{block.source.title}}</a>
    </div>
</template>

<script>
export default {
    name: 'TextBlock',
    props: {
        block: Object
    }
}
</script>

<style scoped>
.text-container {
    max-width: 600px;
    text-align: left;
    font-family: 'Helvetica';
    background-color: transparent;
    padding: 10px 20px 26px;
    border-radius: 8px;
}
</style>