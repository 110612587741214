<template>
  <div v-if="pageCount" class="blog custom-scrollbar" @scroll="onScroll">
    <div class="header">
        <p>Fascinating digital objects found while traveling the Internet.</p>
        <p>Powered by <a href="https://www.are.na/miles-gilbert/collected-media" target="_blank">are.na</a></p>
    </div>
    <div class="container">
        <div v-for="block in pageData" :key="block" class="block">
            <ImageBlock v-if="block.class === 'Image'" :block="block"/>
            <YouTubeBlock v-if="block.class === 'Media' && block.source.provider.name === 'YouTube'" :block="block" />
            <LinkBlock v-if="block.class === 'Link'" :block="block" />
            <TextBlock v-if="block.class === 'Text'" :block="block" />
        </div>
    </div>
    <div v-if="loading && !isMobile" class="load-more">
        <div class="square-rotate-2"></div>
    </div>
    <div v-else @click="LoadPage(this.page)" class="load-more">
        Load more
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import ImageBlock from '@/components/blog/ImageBlock'
import YouTubeBlock from '@/components/blog/YouTubeBlock'
import LinkBlock from '@/components/blog/LinkBlock'
import TextBlock from '@/components/blog/TextBlock'
export default {
    name: 'Blog',
    components: {
    ImageBlock,
    YouTubeBlock,
    LinkBlock,
    TextBlock
},
    data () {
        return {
            posts: null,
            title: null,
            description: null,
            channel: 'collected-media',
            pageCount: null,
            page: null,
            perPage: 10,
            pageData: null,
            loading: true,
            isMobile: false
        }
    },
    methods: {
        GetContent() {
            axios.get('https://api.are.na/v2/channels/' + this.channel  + '?amp;per=' + this.perPage)
            .then((response) => {
                this.pageCount = (Math.ceil(response.data.length / 10))
                const remainder = response.data.length % 10
                this.page = this.pageCount
                this.LoadPage(this.page, remainder)
            })
        },
        LoadPage(pageNum, remainder) {
            this.loading = true
            axios.get('https://api.are.na/v2/channels/' + this.channel + '?page=' + pageNum + '&amp;per=' + this.perPage)
            .then((response) => {
                this.loading = false
                if (this.pageData === null) {
                    this.pageData = response.data.contents.reverse()
                    //check if there's a partial page, if so, load the next 10 images
                    if (remainder) {
                        this.LoadPage(this.page - 1, null)
                    }
                } else {
                    const newData = response.data.contents.reverse()
                    for(var i = 0; i < newData.length; i++) {
                        this.pageData.push(newData[i])
                    }
                }
                this.page = this.page - 1
            })
        },
        onScroll ({ target: { scrollTop, clientHeight, scrollHeight }}) {
            if (scrollTop + clientHeight >= scrollHeight && !this.isMobile) {
                this.LoadPage(this.page)
            }
        }
    },
    mounted() {
        if(window.innerWidth < 900) {
            this.isMobile = true
        }
        this.GetContent()
    }
}
</script>

<style>
.blog {
    grid-area: 1 / 1 / 3 / 1;
    height: 100vh;
    overflow-y: scroll;
    text-align: center;
}
.blog>.header {
    font-family: 'Helvetica';
    margin: 200px auto;
    max-width: 100% !important;
}
.blog h1 {
    font-family: 'Helvetica';
    font-weight: light;
}
.blog .container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.block {
    box-sizing: border-box;
    margin-bottom: 60px;
    margin: 0 auto 200px;
}
.blog .load-more {
    font-family: 'Helvetica';
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 100px;
    cursor: pointer;
}

.blog .candy {
    background: radial-gradient(#FFE6E6, #D8D5FF, #D5FBFF, #D5FFDB);
    background-size: 400% 400%;
    animation: Gradient 45s infinite;
    animation-timing-function: cubic-bezier(.56,0,.56,1);
}
.custom-scrollbar::-webkit-scrollbar {
    width: 0px;
}
.custom-scrollbar::-webkit-scrollbar-thumb { /* Foreground */
    background: rgb(22, 22, 22);
    border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-track { /* Background */
    background: #000;
}
.blog .yt-container iframe {
    width: 100%;
    height: fit-content;
    border-radius: 8px;
}
.square-rotate-2 {
  --size: 24px;
  --accent-opacity: .25;
  --color: currentColor;
  --animation-timing-function: linear;
  --animation-duration: 2s;
  position: relative;
  width: var(--size);
  height: var(--size);
}

.square-rotate-2::before,
.square-rotate-2::after {
  content: '';
  position: absolute;
  transform: rotate(0deg);
  animation: var(--animation-timing-function) var(--animation-duration) infinite square-rotate-2-animation;
}

.square-rotate-2::before {
  inset: 0;
  background-color: var(--color);
  opacity: var(--accent-opacity);
}

.square-rotate-2::after {
  top: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 75%;
  background-color: var(--color);
  animation-direction: reverse;
}

@keyframes square-rotate-2-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
@media screen and (min-width: 900px) {
    .blog .yt-container iframe {
        width: 640px;
        height: 360px;
    }
}
</style>