<template>
    <a class="image-container" @mouseenter="hover = true" @mouseleave="hover = false" :href="CheckSource(block.source)" :class="{noSource: block.source === null}" target="_blank">
        <img :src="block.image.display.url" />
        <p v-if="hover && block.source.title">{{block.source.title}}<span class="material-icons">open_in_new</span></p>
    </a>
</template>

<script>
export default {
    name: 'ImageBlock',
    props: {
        block: Object
    },
    data (){
        return {
            hover: false
        }
    },
    methods: {
        CheckSource(source) {
            if(source) {
                return source.url
            } else {
                return '#'
            }
        }
    }
}
</script>

<style scoped>
img {
    max-width: 100%;
}
.noSource {
    pointer-events: none;
    cursor: inherit;
}
.image-container {
    position: relative;
}
p {
    font-family: 'Helvetica';
    font-size: 12px;
    position: absolute;
    color: #666;
    display: flex;
    align-items: center;
}
p .material-icons {
    font-size: 20px;
    margin-left: 6px;
}
</style>